<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<h1>{{$t('Reclame')}}</h1>
							<div class="uk-width-expand p-pb-3">
								<div v-html="getLanguageField(content[0],'html')"></div>
							</div>
						</div>
						<div class="uk-width-1-4@m p-pb-3 typography-sidepanel">
							<div v-html="getLanguageField(content_sidepanel[0],'html')"></div>
						</div>
					</div>

					<div
						v-for="(template, index) in chiliTemplates"
						:key="index"
						class="p-mb-6"
					>
						<Card>
							<template slot="content">
								<div class="uk-grid">
									<div class="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m">
										<div v-if="template.preview && getLanguageField(template,'preview').data">

											<img
												class="uk-width-1-1 p-shadow-8"
												:src="remote_addr + getLanguageField(template,'preview').data.asset_url"
											/>
										</div>
									</div>
									<div class="uk-width-1-1 uk-width-2-3@s uk-width-3-4@m">
										<div class="typography-main">
											<h2>{{ getLanguageField(template,'title') }}</h2>
											<div v-html="getLanguageField(template,'description')"></div>

											<div>
												<Button
													:label="$t('Personaliseer')"
													class="p-mr-2"
													@click="openChiliEditor(template)"
												/>
												<Button
													v-if="!pdfUrl && pdfGenerationStarted == template.id"
													disabled
													class="p-button-outlined p-mr-2"
													:label="$t('Genereren') + '...'"
												/>
												<Button
													v-if="pdfUrl && pdfGenerationStarted == template.id"
													class="p-button-outlined p-mr-2"
													icon="pi pi-file-pdf"
													:label="$t('Download') + ' PDF'"
													@click="downloadPDF(pdfUrl)"
												/>
												<Button
													v-if="1==2 && template.pdfready && template.pdfpath"
													class="p-button-outlined p-mr-2"
													icon="pi pi-image"
													:label="$t('Download afbeelding')"
													@click="downloadPNG(template.pdfpath)"
												/>

											</div>
										</div>
									</div>
								</div>
							</template>
						</Card>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>

			<div
				v-if="activeChiliTemplate && displayChiliEditor"
				:style="{ position: 'fixed', top: '0', left: '0', zIndex: '1000', width: '100vw', height: '100vh', overflow: 'hidden', background: 'rgba(0,0,0,0.5)', padding: '16px' }"
			>
				<iframe
					id="chiliEditor"
					:src="chiliEditorURL"
					style="display:block;border:0px;width:100%;height:calc(100vh - 32px);padding:0px;margin:0px;"
				></iframe>
			</div>
		</div>
	</layout-default>
</template>

<script>
const axios = require("axios");
const parseString = require("xml2js").parseString;

import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
			content_sidepanel: [{}],
			chiliURL: "https://editor.brndcntrl.com/CHILI",
			chiliTemplates: [],
			chiliIframe: null,
			activeChiliTemplate: null,
			displayChiliEditor: false,
			pdfUrl: null,
			pdfGenerationStarted: null,
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		/*
		CORS ISSUES
		async copyChiliDocument(id) {
			let self = this;
			var config = {
				method: "post",
				url: "https://editor.brndcntrl.com/CHILI/rest-api/v1/resources/Documents/items/" + id + "/copy?newName=0001&folderPath=tmp/ppg_retail_nl/" + id + "/",
				headers: {
					"API-KEY": self.chilikey,
				},
			};

			await axios(config)
				.then(function(response) {
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		*/
		openChiliEditor(template) {
			let self = this;
			self.activeChiliTemplate = JSON.parse(JSON.stringify(template));
			self.displayChiliEditor = true;
			let body = document.getElementsByTagName("body")[0];
			body.classList.add("p-overflow-hidden");
		},
		closeChiliEditor() {
			let self = this;
			self.activeChiliTemplate = null;
			self.displayChiliEditor = false;
			let body = document.getElementsByTagName("body")[0];
			body.classList.remove("p-overflow-hidden");
		},
		postMessageToEditor(message) {
			let iframe = document.getElementById("chiliEditor").contentWindow;
			iframe.postMessage(message, "*");
		},

		checkServerForFile(taskid) {
			let self = this;

			axios
				.post(
					"https://www.clickandorderstore.nl/chilipublish/ajax/TaskGetStatus.asp?taskid=" +
						taskid
				)
				.then((response) => {
					//console.log("xxx", response);
					if (
						!response ||
						!response.data ||
						!response.data.status ||
						response.data.status != "OK"
					) {
						return;
					} else {
						if (response.data.ready && response.data.url) {
							self.pdfUrl = response.data.url;

							//alert("READY");

							return;
						}
					}
					setTimeout(function () {
						self.checkServerForFile(taskid);
					}, 1000);
				});
		},

		downloadImage(url, name) {
			fetch(url)
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.style.display = "none";
					a.href = url;
					// the filename you want
					a.download = name;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				})
				.catch(() => alert("An error sorry"));
		},
		downloadPDF(url) {
			window.open(url);
		},
		downloadPNG(path) {
			console.log(
				"https://www.clickandorderstore.nl/pdf_to_png/?path=" + path
			);
			this.downloadImage(
				"https://www.clickandorderstore.nl/pdf_to_png/?path=" + path,
				path.split("/").pop().replace(path.split(".").pop(), "png")
			);
		},
	},

	computed: {
		chilikey() {
			return this.$cookie.get("chilikey");
		},
		user() {
			let self = this;
			return self.$route.params.user;
		},
		chiliEditorURL() {
			let self = this;
			if (self.activeChiliTemplate) {
				return (
					//"http://localhost:8083?environment=clickandorderstore_nl&document_id=" +
					"https://editor.brndcntrl.com?environment=cp-dme-456&timestamp=" +
					new Date() +
					"&document_id=" +
					self.getLanguageField(
						self.activeChiliTemplate,
						"document_id"
					) +
					"&document_id_temp=" +
					(self.activeChiliTemplate.document_id_temp
						? self.activeChiliTemplate.document_id_temp
						: "") +
					"&key=" +
					self.chilikey +
					"&workspace_id=" +
					self.getLanguageField(
						self.activeChiliTemplate,
						"workspace_id"
					) +
					"&viewpreferences_id=" +
					self.getLanguageField(
						self.activeChiliTemplate,
						"viewpreferences_id"
					) +
					"&settings_id=" +
					self.getLanguageField(
						self.activeChiliTemplate,
						"settings_id"
					) +
					"&path_temp=ppg_retail_be/" +
					self.getLanguageField(
						self.activeChiliTemplate,
						"document_id"
					) +
					"/" +
					"&document_name_temp=" +
					self.$route.params.user.id +
					"&ignoredatasource=false&enable3D=true" +
					"&editor_QUERYSTRING=" +
					"|setqsvars=" +
					(self.activeChiliTemplate.document_id_temp
						? "false"
						: "true") +
					"|Naam=" +
					encodeURIComponent(escape(self.user.account_name)) +
					"|Adres=" +
					encodeURIComponent(escape(self.user.account_address)) +
					"|Postcode=" +
					encodeURIComponent(escape(self.user.account_postalcode)) +
					"|Plaats=" +
					encodeURIComponent(escape(self.user.account_city)) +
					"|E-mailadres=" +
					encodeURIComponent(escape(self.user.account_email)) +
					"|Website=" +
					encodeURIComponent(escape(self.user.account_url)) +
					"|Logo=" +
					encodeURIComponent(escape(self.user.account_logo))
				);
			}
			return null;
		},
	},

	created() {
		let self = this;

		Directus.client.api
			.get("/items/chili", {
				"filter[status][eq]": "published",
				fields: "*,preview.*,translation.*,translation.preview.*",
			})
			.then((response) => {
				this.chiliTemplates = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "reclame",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "reclame-sidepanel",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content_sidepanel = response.data;
			});

		window.addEventListener(
			"message",
			(event) => {
				//if (event.origin !== "https://chili.clickandorderstore.nl") return;

				console.log(event);

				if (self && event && event.data && event.data.action) {
					if (event.data.action == "ready") {
						let logoname = null;
						if (
							self.user.account_logo &&
							self.user.account_logo.data &&
							self.user.account_logo.data.url
						) {
							logoname =
								"logo." +
								self.user.account_logo.data.url
									.split(".")
									.pop();
						}
						if (!self.activeChiliTemplate.document_id_temp) {
							self.postMessageToEditor({
								action: "loaded",
								variables: [
									{
										name: "Naam",
										value: self.user.account_name,
									},
									{
										name: "Adres",
										value: [
											self.user.account_address,
											self.user.account_postalcode,
											self.user.account_city,
										].join(", "),
									},
									{
										name: "Telefoon",
										value: self.user.account_phone,
									},
									{
										name: "E-mailadres",
										value: self.user.account_email,
									},
									{
										name: "Website",
										value: self.user.account_url,
									},
								],
								assets: logoname
									? [
											{
												name: logoname,
												url:
													self.remote_addr +
													self.user.account_logo.data
														.asset_url,
												path:
													"ppg_retail_be/" +
													self.user.id +
													"/",
												variable: "Logo",
											},
									  ]
									: null,
							});
						} else {
							self.postMessageToEditor({
								action: "loaded",
							});
						}
					}
					if (event.data.action == "close") {
						self.closeChiliEditor();
					}
					if (event.data.action == "save") {
						console.log("event", event);

						if (
							event.data.document &&
							event.data.pdf &&
							event.data.chili_document_id &&
							self.activeChiliTemplate &&
							self.activeChiliTemplate.id
						) {
							self.pdfGenerationStarted =
								self.activeChiliTemplate.id;
							self.checkServerForFile(
								//self.chiliTemplates[index]
								//TASK ID:
								event.data.pdf.id
							);
						}
						self.closeChiliEditor();
					}
				}
				return;
			},
			false
		);
	},
	mounted: function () {
		let self = this;
		axios.get(self.remote_addr + "/chili/apikey.php").then((response) => {
			parseString(response.data, function (err, result) {
				if (
					result &&
					result.apiKey &&
					result.apiKey.$ &&
					result.apiKey.$.succeeded == "true"
				) {
					self.$cookie.set("chilikey", result.apiKey.$.key);
				}
			});
		});
	},
};
</script>

<style lang="scss"></style>
