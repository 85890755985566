<template>
	<div class="p-d-inline-block">
		<Button
			:label="$i18n.locale.toUpperCase()"
			class="p-button-sm"
			icon="pi pi-globe"
			@click="$refs.localeMenu.toggle($event)"
		></Button>
		<Menu
			ref="localeMenu"
			:model="items"
			:popup="true"
			appendTo="body"
		/>
	</div>
</template>

<script>
import Menu from "primevue/menu";
import Button from "primevue/button";
import Directus from "@/services/directus/";

export default {
	name: "LocaleSwitcher",
	components: {
		Menu,
		Button,
	},
	data() {
		return {
			items: [
				{
					label: "NL",
					command: () => {
						this.switchLocale("nl", "nl-NL");
					},
				},
				{
					label: "FR",
					command: () => {
						this.switchLocale("fr", "fr-FR");
					},
				},
			],
		};
	},
	methods: {
		switchLocale(locale, locale_directus) {
			//console.log(this.$route);

			this.$cookie.set("locale", locale, "1y");

			this.$route.params.lang = locale == "nl" ? null : locale;

			this.$i18n.locale = locale;

			if (
				this.$route &&
				this.$route.params &&
				this.$route.params.user &&
				this.$route.params.user.id
			) {
				Directus.client.api
					.patch("/users/" + this.$route.params.user.id, {
						locale: locale_directus,
					})
					.then((response) => {
						this.$router.push(this.$route);
					});
			}
		},
	},
	created: function () {
		if (!this.$cookie.get("locale")) {
			this.$cookie.set("locale", "nl", "1y");
		}
		this.$i18n.locale = this.$cookie.get("locale");
	},
};
</script>
