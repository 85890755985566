<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-expand" v-if="!newsId">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<div class="">
								<h1>{{ $t("ArtikelInformatie") }}</h1>
							</div>
							<div class="uk-width-1-1 p-pb-3" v-if="content">
								<div v-html="getLanguageField(content[0], 'html')"></div>
							</div>
						</div>
						<div style="overflow-x:auto;width:100vw;white-space:nowrap;">
							<div class="article_table" style="padding-bottom:150px;">
								<DataTable
									ref="articleTable"
									class="datatable-responsive p-datatable-striped"
									:value="articleData['articles']"
									dataKey="GS1 item number (GTIN/ EAN)"
									:loading="loading"
									:filters="filters"
									:paginator="true"
									:rows="10"
									:autoLayout="true"
									sortField="Article Name PPG"
									:sortOrder="1"
									paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
									:rowsPerPageOptions="[10, 20, 50]"
									currentPageReportTemplate="{first} - {last} / {totalRecords}"
									selectionMode="single"
									:selection.sync="articleData['selected']"
									@row-select="selectArticle"
									@sort="setArticleDataFiltered"
									@filter="setArticleDataFiltered"
								>
									<template>
										<div class="uk-grid uk-grid-small">
											<div class="uk-width-expand uk-visible@m"></div>
											<div class="uk-width-auto p-mb-4">
												<Button
													class="p-button-success "
													icon="pi pi-file-excel"
													:label="articleData['articles_filtered'].length == articleData['articles'].length ? $t('Exporteer alles') : $t('Exporteer') + ' ' + articleData['articles_filtered'].length + ' ' + $t('Items')"
													@click="exportExcel($event)"
												/>
											</div>
											<div class="uk-width-auto p-mb-4">
												<div class="p-inputgroup">
													<Dropdown
														v-model="filters['Brand name']"
														:options="filteredBrands()"
														optionValue="Brand name"
														optionLabel="Brand name"
														:filter="true"
														:placeholder="$t('Merk') + '...'"
														@change="
															() => {
																filters['Functional name'] = null;
															}
														"
													>
														<template #value="slotProps">
															<div v-if="slotProps.value">
																{{ slotProps.value }}
															</div>
															<span v-else>
																{{ slotProps.placeholder }}
															</span>
														</template>
														<template #option="slotProps">
															<div v-if="slotProps.option['Brand name'] != ''">{{ slotProps.option["Brand name"] }}</div>
															<div v-else>{{ slotProps.placeholder }}</div>
														</template>
													</Dropdown>
													<Button icon="pi pi-times" class="" @click="filters['Brand name'] = null" />
												</div>
											</div>
											<div class="uk-width-auto p-mb-4" v-if="1 == 2">
												<div class="p-inputgroup">
													<Dropdown v-model="filters['Functional name']" :options="filteredFunctionals()" optionValue="Functional name" optionLabel="Functional name" :filter="true" :placeholder="$t('Categorie') + '...'">
														<template #value="slotProps">
															<div v-if="slotProps.value">
																{{ slotProps.value }}
															</div>
															<span v-else>
																{{ slotProps.placeholder }}
															</span>
														</template>
														<template #option="slotProps">
															<div v-if="slotProps.option['Functional name'] != ''">{{ slotProps.option["Functional name"] }}</div>
															<div v-else>{{ slotProps.placeholder }}</div>
														</template>
													</Dropdown>
													<Button icon="pi pi-times" class="" @click="filters['Functional name'] = null" />
												</div>
											</div>
											<div class="uk-width-auto p-mb-4">
												<span class="p-input-icon-left">
													<i class="pi pi-search" />
													<InputText v-model="filters['global']" :placeholder="$t('Zoek')" />
												</span>
											</div>
										</div>
									</template>

									<Column header="Image">
										<template #body="slotProps">
											<div style="width:100px;">
												<div v-if="slotProps.data['CDN Link'] && slotProps.data['CDN Link'].length > 5">
													<img :src="slotProps.data['CDN Link']" class="product-image" />
												</div>
												<div v-else>
													<img src="/images/no-image.png" class="product-image" />
												</div>
											</div>
										</template>
									</Column>
									<Column sortable field="GS1 item number (GTIN/ EAN)" header="EAN">
										<template #body="slotProps">
											<VueBarcode :value="slotProps.data['GS1 item number (GTIN/ EAN)']" tag="svg" :options="{ width: 1.5, height: 50 }" :format="'EAN-13'">
												-
											</VueBarcode>
										</template>
									</Column>
									<Column sortable field="Brand name" :header="$t('Merk')"></Column>
									<Column sortable field="Subbrand name Retail" :header="$t('Submerk')"></Column>
									<Column sortable field="Article Name PPG" :header="$t('Naam')"></Column>
									<Column sortable field="Functional name" :header="$t('Type')"></Column>
									<Column sortable field="Net Volume" :header="$t('Netto volume')"></Column>
									<Column sortable field="Volume UOM" :header="$t('Eenheid')"></Column>
									<Column sortable field="Color name" :header="$t('Kleur')"></Column>
									<Column sortable field="Finish" :header="$t('Finish')"></Column>
									<Column sortable field="Finish type (Gloss grade)" :header="$t('Glans type')"></Column>

									<template #paginatorLeft> </template>
									<template #paginatorRight> </template>
								</DataTable>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog header="" :visible.sync="articleDialog.visible" :modal="true" :closeOnEscape="true">
				<div style="width:1200px;max-width:95vw;" v-if="articleData['selected']['GS1 item number (GTIN/ EAN)']">
					<div>
						<div class="uk-grid">
							<div class="uk-width-1-2@m">
								<div v-if="articleData['selected']['CDN Link'] && articleData['selected']['CDN Link'].length > 5">
									<img :src="articleData['selected']['CDN Link']" style="max-width:100%;" />
								</div>
								<div v-else>
									<img src="/images/no-image.png" style="max-width:100%;" />
								</div>
							</div>
							<div class="uk-width-1-2@m">
								<h2 class="t-fg-1">{{ articleData["selected"]["Article Name PPG"] }}</h2>
								<p>{{ articleData["selected"]["Commercial text Retail"] }}</p>
								<ul v-if="articleData['selected']['USP\'s for Retail'] && articleData['selected']['USP\'s for Retail'].length > 10">
									<li v-for="(item, index) in articleData['selected']['USP\'s for Retail'].split('|')" :key="index">{{ item }}</li>
								</ul>
								<div class="uk-width-1-1 uk-position-relative">
									<div class="uk-grid uk-grid-small">
										<div class="uk-width-auto@m" v-if="articleData['selected']['URL of SDS file'] && articleData['selected']['URL of SDS file'].length > 10">
											<div class="p-mb-4">
												<a :href="articleData['selected']['URL of SDS file']" target="_blank" class="p-button"><i class="pi pi-file-pdf p-mr-2"></i>{{ $t("Veiligheidsinformatie") }}</a>
											</div>
										</div>

										<div class="uk-width-auto@m" v-if="articleData['selected']['CDN Link'] && articleData['selected']['CDN Link'].length > 5">
											<div class="p-mb-4">
												<a :href="articleData['selected']['CDN Link']" target="_blank" class="p-button" download><i class="pi pi-download p-mr-2"></i>{{ $t("Packshot") }}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template #footer>
					<Button :label="$t('Sluit')" @click="articleDialog.visible = false" />
				</template>
			</Dialog>
		</div>
	</layout-default>
</template>

<style lang="scss">
.product-image {
	width: 100px;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import XLSX from "xlsx";
import VueXlsx from "vue-js-xlsx";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		XLSX,
		VueXlsx,

		PageLoader,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: true,
			content: [{}],
			menu: {},
			displayModal: false,
			newsId: null,
			lightbox: {
				title: null,
				visible: false,
				image: null,
			},
			filters: {
				"Brand name": null,
				"Functional name": null,
				global: null,
			},
			articleData: {
				articles: [],
				articles_filtered: [],
				selected: {},
				brands: [],
				functionals: [],
			},
			settings: null,
			articleDialog: {
				visible: false,
			},
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},

		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		selectArticle(e) {
			console.log(e);
			this.articleDialog.visible = true;
		},
		exportExcel() {
			let self = this;

			var articleData = _.intersectionBy(self.articleData["articles"], self.articleData["articles_filtered"], "GS1 item number (GTIN/ EAN)");
			//console.log(result);

			var worksheet = XLSX.utils.json_to_sheet(articleData);
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, worksheet, "Articles");
			XLSX.writeFile(wb, "articles.xlsx");
		},
		setArticleDataFiltered(e) {
			this.articleData["articles_filtered"] = e.filteredValue;
		},

		filteredBrands() {
			let self = this;
			if (self.filters["Functional name"]) {
				return _.filter(self.articleData["brands"], function(o) {
					return o["Brand name"] != "" && o["Functional name"] == self.filters["Functional name"];
				});
			} else {
				return _.uniqBy(self.articleData["brands"], "Brand name");
			}
		},

		filteredFunctionals() {
			let self = this;
			if (self.filters["Brand name"]) {
				return _.filter(self.articleData["functionals"], function(o) {
					return o["Functional name"] != "" && o["Brand name"] == self.filters["Brand name"];
				});
			} else {
				return _.uniqBy(self.articleData["functionals"], "Functional name");
			}
		},
	},

	computed: {},
	created() {
		this.newsId = this.$route.params.id;
		this.menu = this.$route.params.menu;

		//const jsonData = this.$xlsx.toJson(data, options)

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "artikel-informatie",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
			});

		Directus.client.api
			.get("/items/settings", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,wholesaler_xls.*",
			})
			.then((response) => {
				this.settings = response.data;

				Axios.get(
					this.remote_addr + this.settings[0].wholesaler_xls.data.asset_url, //hosted with serve
					{ responseType: "arraybuffer" }
				) // replace this with 'arraybuffer' and response.data will be a buffer
					.then((response) => {
						var data = new Uint8Array(response.data);
						var workbook = XLSX.read(data, { type: "array" });
						this.articleData["articles"] = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet0);
						this.articleData["articles"] = _.remove(this.articleData["articles"], function(n) {
							return n["GS1 item number (GTIN/ EAN)"] != "";
						});
						/*
						this.articleData["articles"].forEach((a, index) => {
							this.$set(this.articleData["articles"][index], "Image", "");
							if (!a["Image"]) {
								this.articleData["articles"][index]["Image"] == "";
							}
						});
						*/
						this.articleData["articles_filtered"] = this.articleData["articles"];
						this.loading = false;
						console.log(this.articleData["articles"][0]);

						this.articleData["brands"] = _.sortBy(
							_.uniqWith(
								_.map(this.articleData["articles"], function(object) {
									return _.pick(object, ["Functional name", "Brand name"]);
								}),
								_.isEqual
							),
							"Brand name"
						);

						this.articleData["functionals"] = _.sortBy(
							_.uniqWith(
								_.map(this.articleData["articles"], function(object) {
									return _.pick(object, ["Functional name", "Brand name"]);
								}),
								_.isEqual
							),
							"Functional name"
						);
					});
			});
	},
	mounted: function() {},
	watch: {
		$route(to, from) {
			this.newsId = this.$route.params.id;
			this.menu = this.$route.params.menu;
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style>
.p-dropdown-filter {
	width: 100% !important;
}
</style>
