import Vue from "vue";
import VueRouter from "vue-router";
import LayoutDefault from "../layout/Default.vue";
import LayoutLogin from "../layout/Login.vue";

import Directus from "@/services/directus/";

import Home from "../views/home/";
import Promotions from "../views/promotions/";
import News from "../views/news/";
import ArticleData from "../views/article-data/";
import FileFlow from "../views/fileflow/";
import Brochures from "../views/brochures/";
import Pricelists from "../views/pricelists/";
import Brands from "../views/brands/";
import ColorRecepies from "../views/colorrecepies/";
import Contact from "../views/contact/";
import FormColorComplaint from "../views/forms/color-complaint/";
import Maintenance from "../views/maintenance/";
import Colormaterials from "../views/colormaterials/";
import Welcome from "../views/welcome/";
import Default from "../views/default/";

import Templates from "../views/templates/";
import Training from "../views/training/";
import Socialmedia from "../views/socialmedia/index.vue";
import Socialads from "../views/socialads/index.vue";

import login from "../views/login/login.vue";
import account from "../views/account/index.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/:lang([a-z][a-z])?/login",
		name: "login",
		component: login,
		meta: { layout: LayoutLogin, requiresAuth: false },
	},
	{
		path: "/:lang([a-z][a-z])?/account",
		name: "account",
		component: account,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/resetpassword",
		name: "resetpassword",
		component: login,
		meta: { layout: LayoutLogin, requiresAuth: false },
	},
	{
		path: "/:lang([a-z][a-z])?/promoties/:id?",
		name: "promotions",
		component: Promotions,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/nieuws/:id?",
		name: "news",
		component: News,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/artikel-informatie/:id?",
		name: "article-data",
		component: ArticleData,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/beeldenbank/:id?",
		name: "fileflow",
		component: FileFlow,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/brochures/",
		name: "brochures",
		component: Brochures,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/prijslijsten/:brand_slug?",
		name: "pricelists",
		component: Pricelists,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/merken/",
		name: "brands",
		component: Brands,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/kleurrecepten/",
		name: "colorrecepies",
		component: ColorRecepies,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/",
		name: "home",
		component: Home,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/contact/",
		name: "contact",
		component: Contact,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/kleurklacht-form/",
		name: "form-color-complaint",
		component: FormColorComplaint,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/onderhoud/",
		name: "maintenance",
		component: Maintenance,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/reclame/",
		name: "templates",
		component: Templates,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/training/",
		name: "training",
		component: Training,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/socialmedia/",
		name: "socialmedia",
		component: Socialmedia,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/socialads/",
		name: "socialads",
		component: Socialads,
		meta: { layout: LayoutDefault, requiresAuth: true, reload: true },
	},
	{
		path: "/:lang([a-z][a-z])?/kleurmateriaal/",
		name: "colormaterials",
		component: Colormaterials,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
	{
		path: "/:lang([a-z][a-z])?/welkom/:external_id?",
		name: "welcome",
		component: Welcome,
		meta: { layout: LayoutDefault, requiresAuth: false },
	},
	{
		path: "/:lang([a-z][a-z])?/:slug?",
		name: "default",
		component: Default,
		meta: { layout: LayoutDefault, requiresAuth: true },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

let user = null;
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		Directus.getCurrentUser(Directus.getToken()).then((response) => {
			if (response == null) {
				next({
					name: "login",
					params: { nextUrl: to.fullPath },
					replace: true,
				});
			} else {
				user = response.data;
				console.log(to);
				next();
			}
		});
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	router.currentRoute.params.user = user;
});

export default router;
