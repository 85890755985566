<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<h1>{{ $t("OnderhoudMengmachines") }}</h1>
						</div>
					</div>
					<div class="typography-main">
						<div class="uk-width-expand p-pb-3">
							<div v-html="getLanguageField(content[0], 'html')"></div>
						</div>
						<div class="p-datatable-striped p-datatable">
							<table>
								<thead class="p-datatable-thead">
									<tr>
										<th></th>
										<th>{{ $t("Artikel") }}</th>
										<th>{{ $t("Artikelnr") }}</th>
										<th>{{ $t("EAN") }}</th>
										<th>{{ $t("BestellenPer") }}</th>
										<th>{{ $t("VerpaktPer") }}</th>
									</tr>
								</thead>
								<tbody class="p-datatable-tbody">
									<tr v-for="(article, index) in articles" :key="index">
										<td>
											<div style="width:64px;">
												<div class="uk-position-relative" v-if="article.image && article.image.data && article.image.data.asset_url">
													<img :src="remote_addr + article.image.data.asset_url" style="max-width:64px;max-height:64px;" />
												</div>
											</div>
										</td>
										<td>{{ getLanguageField(article, "description") }}</td>
										<td>{{ article.code }}</td>
										<td>
											<VueBarcode :value="article.ean" tag="svg" :options="{ width: 1.5, height: 50 }" :format="'EAN-13'">
												-
											</VueBarcode>
										</td>
										<td>{{ article.order_per }}</td>
										<td>{{ article.packed_per }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,

		PageLoader,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
			articles: [],
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
	},
	computed: {},
	created() {
		Directus.client.api
			.get("/items/articles", {
				"filter[status][eq]": "published",
				"filter[type][eq]": "ServiceMengmachine",
				fields: "*,image.*,translation.*",
			})
			.then((response) => {
				this.articles = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "mengmachine-artikelen",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function() {},
};
</script>

<style lang="scss"></style>
