import DirectusSDK from "@directus/sdk-js";
import axios from "axios";
import VueCookie from "vue-cookie";

const remote_addr = process.env.VUE_APP_REMOTE_ADDR;
const directus_project = process.env.VUE_APP_DIRECTUS_PROJECT;
const client = new DirectusSDK({
	url: remote_addr + "/directus/public/",
	project: directus_project,

	/*
	persist: true,
	tokenExpirationTime: 5 * 60 * 60 * 1000,
	storage: window.localStorage
	*/
});

const client2 = new DirectusSDK({
	url: remote_addr + "/directus/public/",
	project: directus_project,
	token: "25c57990-bed4-4bf3-b09e-70f7501ceb95",

	/*
	persist: true,
	tokenExpirationTime: 5 * 60 * 60 * 1000,
	storage: window.localStorage
	*/
});

export default {
	remote_addr,
	client,
	client2,

	getToken() {
		let auth = VueCookie.get("auth");
		if (auth != null) {
			auth = JSON.parse(auth);
			if (auth && auth.data && auth.data.token) {
				return auth.data.token;
			}
		} else {
			return null;
		}
	},

	signOut() {
		return client.logout().then((response) => {
			return response;
		});
	},

	signIn(email, password) {
		return client
			.login({
				email: email,
				password: password,
				mode: "cookie",
			})
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.log(error.message);
				return {
					error: {
						message: error.message,
					},
				};
			});
	},

	sendPasswordRecoveryEmail(email) {
		return axios
			.post(
				remote_addr + "/directus/public/" + directus_project + "/auth/password/request",
				{
					email: email,
					reset_url: remote_addr + "/resetpassword",
				},
				{ validateStatus: false }
			)

			.then((response) => {
				return response;
			});
	},

	setNewPassword(password, token) {
		return axios
			.post(
				remote_addr + "/directus/public/" + directus_project + "/auth/password/reset",
				{
					password: password,
					token: token,
				},
				{ validateStatus: false }
			)

			.then((response) => {
				return response;
			});
	},

	getCurrentUser(token) {
		client.config.token = token;
		return client
			.getMe()
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	},

	getLanguageField(obj, field, locale) {
		let returnval = field;
		if (typeof obj != "undefined") {
			returnval = obj[field];
			if (obj.translation) {
				let languages = obj.translation;
				let language = self._.find(languages, { language: locale });
				if (language && language[field] && language[field] != "") {
					returnval = language[field];
				}
			}
		}
		return returnval;
	},

	hierarchy(data = [], locale = "nl", { idKey = "id", parentKey = "parent", childrenKey = "items" } = {}) {
		let tree = [];
		let childrenOf = {};
		data.forEach((item) => {
			item.label = this.getLanguageField(item, "title", locale);
			//
			let { [idKey]: id, [parentKey]: parentId = 0 } = item;
			childrenOf[id] = childrenOf[id] || [];
			item[childrenKey] = childrenOf[id];
			if (parentId) {
				if (!childrenOf[parentId["id"]]) {
					childrenOf[parentId["id"]] = [];
				}
				childrenOf[parentId["id"]].push(item);
			} else {
				tree.push(item);
			}
		});
		tree.forEach((item) => {
			if (item[childrenKey].length == 0) {
				item[childrenKey] = null;
				item.to = { name: item.module, params: { slug: item.slug, lang: locale == "nl" ? null : locale, menu: item } };
			} else {
				item[childrenKey].forEach((item2) => {
					if (item2[childrenKey].length == 0) {
						item2[childrenKey] = null;
						item2.to = { name: item2.module, params: { slug: item2.slug, lang: locale == "nl" ? null : locale, menu: item } };
					} else {
					}
				});
			}
		});
		console.log("x", data, tree);
		return tree;
	},
};
