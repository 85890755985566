<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>
				<div class="uk-container uk-container-expand">
					<div class="uk-grid uk-grid-small typography-main">
						<div class="uk-width-expand p-pb-3">
							<div class="p-pb-3">
								<h1>{{ $t("Beeldenbank") }}</h1>
							</div>
						</div>
						<div class="typography-main uk-width-1-1 p-pb-6">
							<div class="uk-grid uk-grid-small">
								<div class="uk-width-auto" style="text-align: left" v-if="fileflow.categoriesTree && fileflow.categoriesTree.length > 0">
									<Button
										v-show="cat && cat.name"
										:label="cat.name + ' (' + cat.items_count + ')'"
										@click="setCategory(cat)"
										:class="fileflow.categoryId == cat.id ? '' : 'p-button-outlined'"
										class="p-button-sm p-mr-2 p-mb-2"
										v-for="(cat, index) in fileflow.categoriesTree[0].items"
										:key="index"
									/>
								</div>

								<div class="uk-width-expand" style="text-align: right"></div>
							</div>
							<div class="uk-grid uk-grid-small" v-if="fileflow.categoriesSub && fileflow.categoriesSub.length > 0">
								<div class="uk-width-auto" style="text-align: left" v-if="fileflow.categoriesSub && fileflow.categoriesSub.length > 0">
									<Button
										v-show="cat && cat.name"
										:label="cat.name + ' (' + cat.items_count + ')'"
										@click="toggleFilter(cat.id)"
										:class="fileflow.categoryId == cat.id ? '' : 'p-button-outlined'"
										class="p-button-sm p-mr-2 p-mb-2"
										v-for="(cat, index) in fileflow.categoriesSub"
										:key="index"
									/>
								</div>

								<div class="uk-width-expand" style="text-align: right"></div>
							</div>
							<DataView v-if="fileflow.items && fileflow.items.length && fileflow.items.length > 0" :value="fileflow.items" :layout="layout" :paginator="true" :rows="20" :totalRecords="fileflow.total" :sortOrder="sortOrder" :lazy="true" @page="onPage($event)" :sortField="sortField">
								<template #header> </template>

								<template #list="slotProps"> {{ slotProps }}</template>

								<template #grid="slotProps" v-if="fileflow.items.length > 0">
									<div class="uk-width-1-2@s uk-width-1-3@m uk-width-1-5@l uk-width-1-6@xl">
										<div class="p-p-2">
											<Card>
												<template slot="header">
													<div class="uk-position-relative" v-aspect-ratio="'1:1'">
														<img
															class="uk-position-absolute"
															:src="slotProps.data.thumbs.medium"
															style="position: absolute; padding:20px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: cover;"
														/>
													</div>
												</template>
												<template slot="title"> </template>
												<template slot="subtitle">
													<div style="font-size:0.75em;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;">
														<strong>{{ slotProps.data.name }}</strong>
													</div>

													<div style="font-size:0.75em;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;">{{ getFileFlowCategory(slotProps.data.category_id).name }}</div>
												</template>
												<template slot="content"> </template>
												<template slot="footer">
													<div class="uk-grid uk-grid-small uk-flex-middle">
														<div class="uk-width-expand">
															<SplitButton class="p-button-outlined p-button-small" :label="$t('Download')" icon="pi pi-download" @click="fileFlowDownload(item, 'downloadable')" :model="downloadMenu(slotProps.data)"></SplitButton>
														</div>
													</div>
												</template>
											</Card>
										</div>
									</div>
								</template>
							</DataView>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog :header="lightbox.title" :visible.sync="lightbox.visible" :style="{ maxWidth: '95vw' }" :modal="true">
				<div class="p-m-0">
					<img :src="lightbox.image" style="max-width:95vw;" />
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<style>
.p-dataview .p-dataview-content {
	background: transparent !important;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import FileFlow from "@/services/fileflow/";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import TieredMenu from "primevue/tieredmenu";

export default {
	components: {
		LayoutDefault,
		Directus,
		FileFlow,

		PageLoader,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		SplitButton,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		TieredMenu,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: false,
			menu: {},
			displayModal: false,
			lightbox: {
				title: null,
				visible: false,
				image: null,
			},

			fileflow: {
				albums: [],
				items: [],
				total: 0,
				categories: [],
				categoriesAll: [],
				categoriesSub: [],
				categoriesTree: null,
				categoryIdBase: 315,
				categoryIdDefault: 316,
				categoryId: null,
				categoryActive: null,
			},
			category: {},

			layout: "grid",
			sortKey: null,
			sortOrder: null,
			sortField: null,
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},
		getFileFlowCategory(id) {
			let self = this;
			if (id) {
				return _.find(self.fileflow.categoriesAll, { id: id });
			}
			return [];
		},

		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},

		async getItems(offset) {
			let self = this;
			await FileFlow.apiRequest("/api/items/?offset=" + offset + "&category_id=" + self.fileflow.categoryId, "GET", {}, {}).then((response) => {
				if (response.items) {
					self.fileflow.total = response.meta.total;
					self.fileflow.items = response.items;
					//console.log("x", response.meta, response.items);
					self.category = _.find(self.fileflow.categories, { id: self.fileflow.categoryId });
				}
			});
		},
		fileFlowDownload(item, format) {
			FileFlow.apiRequest("/api/downloads", "POST", { download: { format: format, item_ids: [item.id] } }, {}).then((response) => {
				if (response && response.url) {
					location.href = response.url;
				}
			});
		},
		downloadMenu(item) {
			var menumodel = [
				{
					icon: "pi pi-download",
					label: "Origineel",
					command: () => {
						this.fileFlowDownload(item, "downloadable");
					},
				},
				{
					icon: "pi pi-download",
					label: "Print (groot)",
					command: () => {
						this.fileFlowDownload(item, "print_large");
					},
				},
				{
					icon: "pi pi-download",
					label: "Web",
					command: () => {
						this.fileFlowDownload(item, "large");
					},
				},
			];

			return menumodel;
		},
		list_to_tree(list) {
			let self = this;
			var map = {},
				node,
				roots = [],
				i;

			for (i = 0; i < list.length; i += 1) {
				map[list[i].id] = i; // initialize the map
			}

			for (i = 0; i < list.length; i += 1) {
				node = list[i];
				if (node.id == self.fileflow.categoryIdBase) {
					node.label = "Filter...";
				} else {
					node.label = node.name;
				}
				list[i].command = function(event) {
					self.getItems(event.item.id);
				};
				if (node.parent_id !== null) {
					// if you have dangling branches check that map[node.parent_id] exists
					if (!list[map[node.parent_id]].items) {
						list[map[node.parent_id]].items = [];
					}
					list[map[node.parent_id]].items.push(node);
				} else {
					roots.push(node);
				}
			}
			return roots;
		},
		toggleFilter(catid) {
			let self = this;
			if (self.fileflow.categoryId == catid) {
				self.fileflow.categoryId = null;
			} else {
				self.fileflow.categoryId = catid;
			}

			self.getItems(0);
		},
		setCategory(cat) {
			if (cat.id != this.$route.query.category) {
				this.$router.push({ name: "fileflow", query: { category: cat.id } });
				this.toggleFilter(cat.id);
			}
		},
		onPage(event) {
			this.getItems(event.first);
		},
		init() {
			this.fileflow.categoryId = this.$route.query.category;
			if (!this.fileflow.categoryId) {
				this.fileflow.categoryId = this.fileflow.categoryIdDefault;
			}
		},
	},

	computed: {},
	mounted() {
		FileFlow.apiRequest("/api/albums", "GET", {}, {}).then((response) => {
			if (response.albums) {
				this.fileflow.albums = response.albums;
				//console.log("this.fileflow.albums", this.fileflow.albums);
			}
		});

		FileFlow.apiRequest("/api/categories", "GET", {}, {}).then((response) => {
			let self = this;
			if (response.categories) {
				console.log(response.categories);
				self.fileflow.categoriesAll = response.categories;
				self.fileflow.categories = _.filter(response.categories, function(o) {
					return o.parent_id == self.fileflow.categoryIdBase || o.id == self.fileflow.categoryIdBase;
				});

				//console.log("self.fileflow.categories", self.fileflow.categories);
				//console.log("categories", self.fileflow.categories);
				self.fileflow.categoriesTree = self.list_to_tree(self.fileflow.categories);
				//console.log("list_to_tree", self.list_to_tree(self.fileflow.categories));

				this.init();
				self.fileflow.categoriesSub = _.filter(self.fileflow.categoriesAll, function(o) {
					return o.parent_id == self.fileflow.categoryId;
				});
				this.getItems(0);
			}
		});
	},
	created() {},
	watch: {
		$route(to, from) {
			let self = this;
			self.menu = self.$route.params.menu;
			window.scrollTo(0, 0);
			self.init();
			self.fileflow.categoriesSub = _.filter(self.fileflow.categoriesAll, function(o) {
				return o.parent_id == self.fileflow.categoryId;
			});
			self.getItems(0);
		},
	},
};
</script>

<style lang="scss"></style>
