import axios from "axios";

export default {
	apiRequest(endpoint, method, data, params) {
		return axios({
			method: "POST",
			url: "https://www.ppg-retail-be.be/development/test/fileflow/api/request.php",
			headers: { "Content-Type": "application/x-www-form- urlencoded" },
			params: {},
			data: {
				endpoint: endpoint,
				method: method,
				data: data,
				params: params,
			},
		}).then((response) => {
			if (response && response.data && response.data) {
				return response.data;
			} else {
				return {};
			}
		});
	},
};
