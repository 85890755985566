import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import VueLodash from "vue-lodash";
import lodash from "lodash";

import "@/scss/main.scss";
import "font-awesome/scss/font-awesome.scss";

import "es6-promise/auto";

Vue.config.productionTip = false;

Vue.use(Vuex);
const store = new Vuex.Store({
	state: {
		count: 0,
	},
	mutations: {},
	actions: {},

	getters: {},
});

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);

import VueCookie from "vue-cookie";
Vue.use(VueCookie);

import i18n from "./i18n";

Vue.use(VueLodash, { lodash: lodash });

import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import nl from "vee-validate/dist/locale/nl.json";
import fr from "vee-validate/dist/locale/fr.json";

import { localize } from "vee-validate";

localize({ nl, fr });

localize({
	nl: {
		names: {
			Voornaam: "Uw voornaam",
			Achternaam: "Uw achternaam",
			Email: "E-mail",
			Telefoon: "Telefoon",
			Onderwerp: "Onderwerp",
			Bericht: "Vragen of opmerkingen",
		},
	},
	fr: {
		names: {
			Voornaam: "Votre prénom",
			Achternaam: "Votre nom",
			Email: "E-mail",
			Telefoon: "Téléphone",
			Onderwerp: "Sujet",
			Bericht: "Questions ou remarques",
		},
	},
});

localize(i18n.locale);

import VueQrcode from "@chenfengyuan/vue-qrcode";

Vue.component(VueQrcode.name, VueQrcode);

import VueMatchHeights from "vue-match-heights";

Vue.use(VueMatchHeights, {
	disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

import AspectRatio from "v-aspect-ratio";

Vue.use(AspectRatio);

import VueMasonry from "vue-masonry-css";

Vue.use(VueMasonry);

const moment = require("moment");
require("moment/locale/nl");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
	moment,
});

import VueGtag from "vue-gtag";

Vue.use(
	VueGtag,
	{
		config: { id: "G-3BLHZZ4WD9" },
	},
	router
);

import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

//console.log(router);

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
	// jacked from: https://github.com/sindresorhus/pretty-bytes
	if (typeof num !== "number" || isNaN(num)) {
		throw new TypeError("Expected a number");
	}

	var exponent;
	var unit;
	var neg = num < 0;
	var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	if (neg) {
		num = -num;
	}

	if (num < 1) {
		return (neg ? "-" : "") + num + " B";
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
	unit = units[exponent];

	return (neg ? "-" : "") + num + " " + unit;
});

new Vue({
	router,
	store,
	i18n,
	render: function(h) {
		return h(App);
	},
}).$mount("#app");
