<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid">
						<div class="uk-width-expand p-pb-3  typography-main">
							<h1>{{$t('Training')}}</h1>
							<div class="uk-width-expand p-pb-3">
								<div v-html="getLanguageField(content[0],'html')"></div>
							</div>
						</div>
						<div
							class="uk-width-1-4@m p-pb-3  typography-sidepanel"
							style=""
						>
							<div v-html="getLanguageField(content_sidepanel[0],'html')"></div>
						</div>
					</div>

					<div v-if="trainings">
						<div
							v-for="(training, index) in trainings"
							:key="index"
							class="p-mb-6"
						>
							<Card>
								<template slot="content">
									<div class="uk-grid">
										<div
											class="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m"
											v-if="training.image && getLanguageField(training,'image').data"
										>
											<div>
												<img
													class="uk-width-1-1 p-shadow-8"
													:src="(remote_addr + getLanguageField(training,'image').data.asset_url)"
												/>
											</div>
										</div>
										<div class="uk-width-1-1 uk-width-expand">
											<div class="typography-main">
												<h2>{{ getLanguageField(training,'title') }}</h2>
												<div v-html="getLanguageField(training,'description')"></div>

												<div v-if="training.url">
													<Button
														:label="$t('Start')"
														class="p-mr-2"
														@click="openTraining(training)"
													/>
												</div>
											</div>
										</div>
									</div>
								</template>
							</Card>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>

			<Dialog
				:header="activeTraining && activeTraining.title ? getLanguageField(activeTraining,'title') : ''"
				:visible.sync="displayTraining"
				@hide="closeTraining"
			>
				<div v-if="activeTraining && activeTraining.url">
					<iframe
						id="trainingIframe"
						:src="'/trainingen/' + getLanguageField(activeTraining,'url')"
						style="display:block;border:0px;width:calc(100vw - 32px);height:calc(100vh - 262px);padding:0px;margin:0px;"
					></iframe>
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<script>
const axios = require("axios");
const parseString = require("xml2js").parseString;

import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
			content_sidepanel: [{}],
			activeTraining: null,
			displayTraining: false,
			trainings: null,
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		openTraining(training) {
			let self = this;
			self.activeTraining = JSON.parse(JSON.stringify(training));
			self.displayTraining = true;
			let body = document.getElementsByTagName("body")[0];
			body.classList.add("p-overflow-hidden");
		},
		closeTraining() {
			let self = this;
			self.activeTraining = null;
			self.displayTraining = false;
			let body = document.getElementsByTagName("body")[0];
			body.classList.remove("p-overflow-hidden");
		},
	},

	computed: {},

	created() {
		let self = this;

		Directus.client.api
			.get("/items/training", {
				"filter[status][eq]": "published",
				"filter[date_show_on_website][lte]": "now",
				"filter[date_hide_from_website][gte]": "now",
				fields: "*,image.*,translation.*,translation.image.*",
			})
			.then((response) => {
				this.trainings = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "training",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
			});
		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "training-sidepanel",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content_sidepanel = response.data;
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
