<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div
				class=""
				v-if="loading == false"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<div v-if="!newsId">
								<div class="uk-width-expand p-pb-3">
									<h1>{{ $t("Nieuws") }}</h1>
								</div>

								<masonry
									:cols="{ default: 2, 1000: 1, 700: 1 }"
									:gutter="36"
								>
									<div
										v-for="(news, index) in topNews"
										:key="index"
										class="p-mb-5"
									>
										<Card>
											<template
												slot="header"
												v-if="news.image"
											>
												<div
													class="uk-position-relative"
													v-aspect-ratio="'4:3'"
												>
													<img
														class="uk-position-absolute"
														:src="remote_addr + getLanguageField(news, 'image').data.asset_url + '?w=800&h=600&q=100&f=contain'"
														style="position: absolute;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;"
													/>
												</div>
											</template>
											<template slot="title">
												{{ getLanguageField(news, "title") }}
											</template>
											<template slot="subtitle"> {{ getLanguageField(news, "subtitle") }} </template>
											<template slot="content">
												<div class="p-mb-4">
													<small>{{ news.date | moment("D MMMM YYYY") }}</small>
												</div>

												<div v-html="getLanguageField(news, 'content_short')"></div>
											</template>
											<template slot="footer">
												<div class="uk-grid uk-grid-small uk-flex-middle">
													<div class="uk-width-expand">
														<Button
															icon="pi pi-arrow-right"
															:label="$t('leesMeer')"
															@click="gotoNews(news.id)"
														/>
													</div>
												</div>
											</template>
										</Card>
									</div>
								</masonry>
							</div>

							<div v-if="newsId">
								<div
									class="uk-container uk-container-large typography-main"
									v-for="(news, index) in currentNews"
									:key="index"
								>
									<div class="p-mb-5">
										<h1>{{ getLanguageField(news, "title") }}</h1>
									</div>

									<Card class="p-mb-6">
										<template slot="content">
											<div>
												<div class="uk-grid">
													<div class="uk-width-expand">
														<div class="p-mb-4">
															<small>{{ news.date | moment("D MMMM YYYY") }}</small>
														</div>
														<div class="p-mb-4">
															<div v-html="getLanguageField(news, 'content')"></div>
														</div>
														<div
															class="p-mb-4"
															v-if="news.image2"
														>
															<img
																:src="remote_addr + getLanguageField(news, 'image2').data.asset_url"
																style="max-width:300px;"
															/>
														</div>
													</div>
													<div
														class="uk-width-1-2@m"
														v-if="news.image"
													>

														<img
															:src="remote_addr + getLanguageField(news, 'image').data.asset_url"
															class="uk-width-1-1"
														/>
													</div>
												</div>
											</div>
										</template>
									</Card>

									<div v-if="news.files && news.files.length > 0">
										<div class="p-mb-4">
											<h3>{{ $t("BeschikbaarVoorDownload") }}:</h3>
										</div>
										<div class="uk-grid uk-grid-small p-mb-4">
											<div
												class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-3@l uk-width-1-4@xl p-mb-3"
												v-for="(file, index) in getLanguageField(news, 'files')"
												:key="index"
											>
												<div
													style="position:relative;cursor:pointer"
													@click="openFile(file.directus_files_id.data.full_url)"
												>
													<Card>
														<template slot="content">
															<div>
																<div>
																	<div class="uk-grid uk-grid-small uk-flex-middle">
																		<div class="uk-width-auto">
																			<div :class="'fi fi-size-xs fi-' + getExtension(file.directus_files_id.filename_disk)">
																				<div class="fi-content">
																					{{ getExtension(file.directus_files_id.filename_disk) }}
																				</div>
																			</div>
																		</div>
																		<div
																			class="uk-width-expand p-text-nowrap p-text-truncate"
																			style="font-size:0.85rem;"
																		>
																			<div>
																				{{ file.directus_files_id.title }}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</template>
													</Card>
												</div>
											</div>
										</div>
									</div>

									<div class="p-mb-4">
										<Button
											icon="pi pi-arrow-left"
											class="p-button-outlined"
											:label="$t('Nieuws')"
											@click="$router.push({ name: 'news', params: { id: null } })"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="uk-width-1-1 uk-width-1-3@l uk-width-1-4@xl p-mb-5">
							<div class="p-mt-3 p-mb-4 typography-main">
								<h3>{{ $t("Archief") }}</h3>
							</div>

							<div
								class="p-mb-5"
								v-if="brands && brands.length > 0"
							>
								<div class="p-inputgroup">
									<Dropdown
										class="uk-width-1-1"
										v-model="brand"
										:options="brands"
										optionLabel="brand_id.name"
										:editable="false"
										:placeholder="$t('Merk') + '...'"
									/><Button
										icon="pi pi-times"
										class=""
										@click="brand = {}"
									/>
								</div>
							</div>
							<div class="typography-main">
								<div
									v-for="(news, index) in filterednews"
									:key="index"
									@click="gotoNews(news.id)"
								>
									<Card
										class="p-mb-2"
										style="cursor:pointer;"
									>
										<template slot="content">
											<div class="uk-position-relative">
												<div>
													<small>{{ news.date | moment("D MMMM YYYY") }}</small>
												</div>
												<div>
													<h4>{{ getLanguageField(news, "title") }}</h4>
												</div>
												<div class="uk-position-bottom-right"><i class="pi pi-arrow-right"></i></div>
											</div>
										</template>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog
				:header="lightbox.title"
				:visible.sync="lightbox.visible"
				:style="{ maxWidth: '95vw' }"
				:modal="true"
			>
				<div class="p-m-0">
					<img
						:src="lightbox.image"
						style="max-width:95vw;"
					/>
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		PageLoader,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: false,
			menu: {},
			displayModal: false,
			newsId: null,
			lightbox: {
				title: null,
				visible: false,
				image: null,
			},
			brand: {},
			brands: [],
			news: [],
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},
		gotoNews(id) {
			this.$router.push({
				name: "news",
				params: {
					id: id,
				},
			});
		},
		getExtension(filename) {
			var re = /(?:\.([^.]+))?$/;
			return re.exec(filename)[1];
		},
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		openFile(file) {
			window.open(file);
		},
	},

	computed: {
		filterednews() {
			let self = this;
			return self.news.filter((news) => {
				if (
					news.brand_id == null &&
					self.brand &&
					self.brand.brand_id
				) {
					return false;
				}
				if (self.brand && self.brand.brand_id) {
					return self.brand.brand_id.id == news.brand_id.id;
				}
				return true;
			});
		},
		currentNews() {
			return this.news.filter((news) => {
				return news.id == this.newsId;
			});
		},
		topNews() {
			return this.news.filter((news) => {
				return news.show_large == 1;
			});
		},
	},
	created() {
		this.loading = true;
		this.newsId = this.$route.params.id;
		this.menu = this.$route.params.menu;

		Directus.client.api
			.get("/items/news", {
				"filter[status][eq]": "published",
				"filter[date_show_on_website][lte]": "now",
				"filter[date_hide_from_website][gte]": "now",
				fields: "*,image.*,translation.*,translation.image.*,files.directus_files_id.*,brand_id.*.*,translation.files.directus_files_id.*",
				sort: "-date",
			})
			.then((response) => {
				this.loading = false;
				this.news = response.data;
				this.brands = _.uniqBy(this.news, "brand_id.id");

				this.brands = _.filter(this.brands, function (o) {
					return o.brand_id != null;
				});
			});
	},
	mounted: function () {
		this.$moment.locale(this.$i18n.locale);
	},
	watch: {
		$route(to, from) {
			this.newsId = this.$route.params.id;
			this.menu = this.$route.params.menu;
			window.scrollTo(0, 0);
			this.$moment.locale(this.$i18n.locale);
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss"></style>
