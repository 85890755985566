<template>
	<div class="LayoutDefault">
		<NavBar />

		<div>
			<div>
				<slot />
			</div>
		</div>

		<div class="uk-width-1-1 t-bg-1 t-fg-white footer">
			<div class="uk-container uk-container-large">
				<div class="uk-grid">
					<div class="uk-width-expand@l">
						<div class="p-pt-3 p-pb-3">&copy; PPG Coatings Belgium BV/SRL</div>
					</div>
					<div class="uk-width-auto@l">
						<NavBarFooter />
					</div>
				</div>
				<div class="">
					<div class="uk-grid">
						<div
							class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m uk-width-expand@l p-mb-4"
							v-for="(brand, index) in brands"
							:key="index"
						>
							<Card v-if="brand.url">
								<template slot="content">
									<div
										class="uk-position-relative"
										v-aspect-ratio="'1:1'"
										v-if="brand && brand.logo && brand.logo.data"
									>
										<img
											class="uk-position-absolute"
											:src="remote_addr + brand.logo.data.asset_url + '?w=800&h=400&q=100&f=contain'"
											style="position: absolute; padding:0px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;
													cursor:pointer !important;
													"
											@click="openUrl(brand.url)"
										/>
									</div>
									<div
										class="uk-position-relative"
										v-aspect-ratio="'1:1'"
										v-else
									>
										<div
											class="uk-position-absolute"
											style="position: absolute; padding:20px; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; cursor:pointer;"
										>{{ brand.name }}</div>
									</div>
								</template>
							</Card>
						</div>
					</div>
				</div>
				<div class="p-pt-3 p-pb-3">
					<div class="uk-grid p-is">
						<div
							class="uk-width-expand@l"
							style="font-size:0.85em !important;"
						>
							<div>{{ $t("contactadres") }}: Corda Campus, Gebouw 5, Kempische Steenweg 297 bus 7, 3500 Hasselt, Belgium | <a
									href="https://www.ppg.com"
									style="font-size:1em !important;"
									class="t-fg-white"
								>www.ppg.com</a></div>
							<div>{{ $t("maatschappelijkeZetel") }}: Haachtsesteenweg 1465 - TSA 23510, 1130 Brussel, Belgium</div>
						</div>
						<div
							class="uk-width-auto@l"
							style="font-size:0.85em !important;"
						>{{ $t("Ondernemingsnummer") }}: BE 0403.103.789</div>
					</div>
				</div>
			</div>
		</div>
		<cookie-law theme="base">
			<div slot-scope="props">
				<div class="uk-grid">
					<div
						class="uk-width-1-1 uk-width-expand@m"
						style="cursor:pointer;"
						v-html="$t('cookieText')"
						@click="$router.push({ name: 'default', params: { slug: 'cookies', lang: $i18n.locale } })"
					></div>
					<div class="uk-width-1-1 uk-width-auto@m">
						<Button @click="props.accept">OK</Button>
					</div>
				</div>
			</div>
		</cookie-law>
	</div>
</template>

<script>
import CookieLaw from "vue-cookie-law";

import NavBar from "@/components/layout/NavBar";
import NavBarFooter from "@/components/layout/NavBar_Footer";

import Directus from "@/services/directus/";

import Card from "primevue/card";
import Button from "primevue/button";

export default {
	components: {
		CookieLaw,

		NavBar,
		NavBarFooter,

		Directus,

		Card,
		Button,
	},

	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			brands: [],
		};
	},
	mounted() {
		Directus.client.api
			.get("/items/brands", {
				"filter[status][eq]": "published",
				"filter[url][nempty]": "",
				fields: "*,logo.*",
			})
			.then((response) => {
				this.brands = response.data;
				console.log(this.brands);
			});
	},
	methods: {
		openUrl(url) {
			if (url) {
				window.open(url);
			}
		},
	},
};
</script>

<style scoped></style>
