<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-expand">
					<div class="uk-grid uk-grid-small typography-main">
						<div class="uk-width-expand p-pb-3">
							<div class="p-pb-3">
								<h1>{{ $t("Brochures") }}</h1>
							</div>
						</div>
						<div class="typography-main uk-width-1-1 p-pb-6">
							<div class="uk-grid uk-grid-small p-mb-4">
								<div class="uk-width-auto" style="text-align: left">
									<div class="p-inputgroup"><Dropdown v-model="brand" :options="brands" optionLabel="brand_id.name" :editable="false" :placeholder="$t('Merk') + '...'" /><Button icon="pi pi-times" class="" @click="brand = {}" /></div>
								</div>
								<div class="uk-width-auto" style="text-align: left">
									<div class="p-inputgroup"><Dropdown v-model="language" :options="languages" optionLabel="label" optionValue="value" :editable="false" placeholder="Taal..." /><Button icon="pi pi-times" class="" @click="language = {}" /></div>
								</div>

								<div class="uk-width-expand" style="text-align: right"></div>
							</div>
							<div
								class="matchheigts-container"
								v-if="brochures && brochures.length > 0"
								style="margin:-7px;"
								v-match-heights="{
									el: ['.matchheigts-container .brochure-container', '.matchheigts-container .brochure-footer'], // Array of selectors to fix
									disabled: [
										// Array of heights where the plugin will not set the heights
										767, // If a string is provided this will be used as a max bound
										[920, 1200], // If an array is provided it will be used as min-max bounds in that order
									],
								}"
							>
								<DataView :value="filteredBrochures" :layout="layout" :rows="50">
									<template #list="slotProps"> {{ slotProps }}</template>

									<template #grid="slotProps">
										<div class="uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l uk-width-1-5@xl" v-if="!brand.brand_id || (slotProps && slotProps.data && slotProps.data.brand_id.id == brand.brand_id.id)">
											<div class="p-p-2">
												<div class="brochure-container t-bg-white br shdw uk-position-relative">
													<div class="p-pl-3 p-pt-3 p-pr-3 p-mb-2" style="border-bottom:2px solid #EAEAEA;">
														<div v-if="slotProps.data.image">
															<img v-if="slotProps.data.image.data" :src="remote_addr + slotProps.data.image.data.asset_url + ''" style="cursor:pointer;" class="uk-width-1-1 br shdw" @click="openPDF(slotProps.data)" />
														</div>
														<div v-else>
															<img
																v-if="slotProps.data.pdf && slotProps.data.pdf.data"
																:src="'https://www.ppg-retail-be.be/services/resize/?url=../..' + slotProps.data.pdf.data.full_url.replace(remote_addr, '') + '[0]&mwidth=500&mheight=500'"
																style="cursor:pointer;"
																class="uk-width-1-1 br shdw"
																@click="openPDF(slotProps.data)"
															/>
															<div class="uk-position-center p-p-5 p-text-center" v-else>{{ slotProps.data.name }}</div>
														</div>
													</div>

													<div class="brochure-footer p-pl-3 p-pr-3 uk-width-1-1">
														<div class="uk-grid uk-grid-small uk-flex-bottom">
															<div class="uk-width-expand">
																<div style="font-size:0.85em;line-height:1em;">{{ slotProps.data.sapcode }}</div>
																<div class="p-text-left" style="margin-left:-14px;">
																	<VueBarcode v-if="slotProps.data.eancode" :value="slotProps.data.eancode" tag="svg" :options="{ width: 1.2, height: 30 }" :format="'EAN-13'">
																		-
																	</VueBarcode>
																</div>
															</div>
															<div class="uk-width-auto">
																<Button v-tooltip.top="$t('Download')" class="p-button-sm p-mb-3" icon="pi pi-file-pdf" @click="openPDF(slotProps.data)" v-if="slotProps.data.pdf && slotProps.data.pdf.data"></Button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</DataView>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog :header="lightbox.title" :visible.sync="lightbox.visible" :style="{ maxWidth: '95vw' }" :modal="true">
				<div class="p-m-0">
					<pdf :src="lightbox.pdf" @num-pages="pageCount = $event" @page-loaded="currentPage = $event"></pdf>
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<style>
.p-dataview .p-dataview-content {
	background: transparent !important;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import pdf from "vue-pdf";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		pdf,

		PageLoader,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		SplitButton,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: false,
			menu: {},
			displayModal: false,
			lightbox: {
				title: null,
				visible: false,
				image: null,
				pdf: null,
			},

			files: [],
			brochures: [],
			brands: [],
			brand: {},
			languages: [
				{
					label: "NL",
					value: "nl",
				},
				{
					label: "FR",
					value: "fr",
				},
			],
			language: null,
			layout: "grid",
			sortKey: null,
			sortOrder: null,
			sortField: null,
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},

		openPDF(file) {
			window.open(file.pdf.data.asset_url);
		},

		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
	},

	computed: {
		filteredBrochures() {
			let self = this;
			console.log("l", self.language);
			return _.filter(self.brochures, function(brochure) {
				let showBrand = false;
				let showLanguage = false;
				if (!self.brand.brand_id || (brochure && brochure.brand_id.id == self.brand.brand_id.id)) {
					showBrand = true;
				}
				if (typeof self.language != "string" || (brochure && brochure.language == self.language)) {
					showLanguage = true;
				}
				return (showBrand == showLanguage) == true;
			});
		},
	},

	created() {
		Directus.client.api
			.get("/items/brochures", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,pdf.*,image.*,*,brand_id.*",
			})
			.then((response) => {
				this.brochures = response.data;

				this.brands = _.uniqBy(this.brochures, "brand_id.id");
			});
	},
	mounted: function() {
		this.language = this.$i18n.locale;
	},
	watch: {
		$route(to, from) {
			this.menu = this.$route.params.menu;
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
