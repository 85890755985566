<template>
	<layout-default>
		<div
			class="uk-position-center"
			v-if="loading == true"
		>
			<ProgressSpinner></ProgressSpinner>
		</div>
		<div
			class=""
			v-if="loading == false && content && content.length>0"
		>
			<div class="p-mb-6"></div>

			<div
				class="uk-container uk-container-large"
				v-if="user"
			>
				<div class="uk-grid typography-main">
					<div class="uk-width-expand p-pb-3">
						<div class="">
							<h1>{{$t("Mijn account")}}</h1>
						</div>
						<div
							class="uk-width-1-1 p-pb-3"
							v-if="content"
						>
							<div v-html="getLanguageField(content[0],'html')"></div>
						</div>
					</div>
				</div>
				<div class="uk-grid typography-main">
					<div class="uk-width-expand p-pb-3">
						<div class="uk-width-1-1 p-pb-3">
							<div class="typography-main">
								<ValidationObserver
									ref="observer"
									v-slot="{ invalid }"
								>
									<div v-if="form && form.user">
										<div class="p-fluid p-grid">
											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Bedrijfsnaam"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_name"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t('Bedrijfsnaam')}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Adres"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_address"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Adres")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Postcode"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="email"
																v-model="form.user.account_postalcode"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Postcode")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Plaats"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_city"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Plaats")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Telefoon"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_phone"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Telefoon")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules="required|email"
														name="E-mail"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_email"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Email")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="p-field p-col-12 p-md-6">
												<div>
													<ValidationProvider
														v-slot="v"
														rules=""
														name="Website"
													>
														<span class="p-float-label p-input-icon-right">
															<i
																class="pi pi-exclamation-triangle"
																v-if="v.errors.length > 0"
																v-tooltip.bottom="v.errors[0]"
															/>
															<InputText
																type="text"
																v-model="form.user.account_url"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label>{{$t("Website")}}</label>
														</span>
													</ValidationProvider>
												</div>
											</div>
											<div class="p-field p-col-12 p-md-6">
												<div
													style="max-width:50%;margin-bottom:10px;"
													v-if="form.user && form.user.account_logo && form.user.account_logo.data && form.user.account_logo.data.url"
												>
													<pdf
														ref="pdfpreview"
														:src="pdfdata"
														:page="1"
														style="border:1px solid #CCC;overflow:hidden;"
													>
														<template slot="loading">
															loading logo...
														</template>
													</pdf>
												</div>

												<div>
													<Button
														label="Verwijder"
														icon="pi pi-times"
														class="p-button-danger p-mr-2"
														style="width:auto;"
														@click="deleteLogo()"
														v-if="form.user && form.user.account_logo && form.user.account_logo.data && form.user.account_logo.data.url"
													/>
													<FileUpload
														class="p-d-inline"
														mode="basic"
														ref="uploader"
														name="file[]"
														:auto="true"
														:customUpload="true"
														@uploader="uploadLogo"
														:multiple="false"
														:maxFileSize="15000000"
														accept="application/pdf"
														:chooseLabel="$t('Upload uw logo') + ' (pdf)'"
														v-else
													/>
												</div>
											</div>

											<div class="p-field p-col-12">
												<div>
													<Button
														:label="$t('Opslaan')"
														style="width:auto;"
														@click="submitForm()"
														:disabled="invalid"
													/>
												</div>
											</div>
										</div>
									</div>
								</ValidationObserver>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toast />
		</div>
	</layout-default>
</template>

<script>
const axios = require("axios");
const parseString = require("xml2js").parseString;

import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";

import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Message from "primevue/message";
import FileUpload from "primevue/fileupload";

import pdf from "pdfvuer";

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,

		Card,
		Button,
		InputText,
		Toast,
		Message,
		FileUpload,

		pdf,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			loading: true,
			content: [{}],
			form: {
				user: null,
			},
			pdfdata: null,
		};
	},
	computed: {
		user() {
			let self = this;
			return self.$route.params.user;
		},
	},
	props: {
		nextUrl: String,
	},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		submitForm() {
			let self = this;
			let observer = self.$refs["observer"];
			console.log(observer);
			console.log(self.user);
			if (observer.flags.valid == true && this.user) {
				Directus.client.api
					.patch("/users/" + this.user.id, self.form.user)
					.then((response) => {
						//console.log(self.user);
						//console.log(self.form.user);

						Directus.client2.api
							.post("/mail", {
								to: ["ppgretailbe@ppg.com", "dev@targateam.nl"],
								subject: "PPG Retail BE - Changes in account",
								body: "<b>CUSTOMER: {{user.customer_id}}</b><br><br>name: {{form.account_name}}<br>{{form.account_address}}<br>postal code: {{form.account_postalcode}}<br>city: {{form.account_city}}<br>phone: {{form.account_phone}}<br>email: {{form.account_email}}<br>url: {{form.account_url}}",
								type: "html",
								data: {
									user: self.user,
									form: self.form.user,
								},
							})
							.then((response) => {
								self.$toast.add({
									severity: "success",
									summary: "Success Message",
									detail: "Account saved",
									life: 3000,
								});
							});
					});
			}
		},
		loadPDF() {
			let self = this;
			if (
				self.form.user &&
				self.form.user.account_logo &&
				self.form.user.account_logo.data &&
				self.form.user.account_logo.data.asset_url
			) {
				self.pdfdata = pdf.createLoadingTask(
					self.remote_addr +
						self.form.user.account_logo.data.asset_url
				);
				self.pdfdata.then((pdf) => {});
			}
		},
		uploadLogo() {
			let self = this;
			self.uploadFile().then((response) => {
				if (response) {
					self.form.user.account_logo = response;
					self.loadPDF();
					Directus.client.api
						.patch("/users/" + self.user.id, {
							account_logo: self.form.user.account_logo,
						})
						.then((response) => {
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: "Logo uploaded",
								life: 3000,
							});
						});
				}
			});
		},
		deleteLogo() {
			let self = this;
			self.form.user.account_logo = null;
			/*
			Directus.client.api
				.patch("/users/" + self.user.id, {
					account_logo: self.form.user.account_logo,
				})
				.then((response) => {
					this.$toast.add({ severity: "success", summary: "Success Message", detail: "Logo deleted", life: 3000 });
				});
				*/
		},

		async uploadFile() {
			const fileInput = this.$refs.uploader;
			const formData = new FormData();

			formData.append("file", fileInput.files[0]);

			return await Directus.client.api
				.post("/files", formData)
				.then((response) => {
					if (response && response.data) {
						console.log(response);
						return response.data;
					}
				});
		},
	},
	mounted: function () {
		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "mijn-account",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
				this.loading = false;
			});
	},
	created() {
		this.form.user = JSON.parse(JSON.stringify(this.$route.params.user));
		this.loadPDF();
	},
};
</script>

<style scoped></style>
