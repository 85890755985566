<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div
				class=""
				v-if="loading == false"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="typography-main">
						<div class="uk-width-expand p-pb-3">
							<div v-html="getLanguageField(content[0], 'html')"></div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		PageLoader,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			loading: true,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
			slug: null,
			menu: {},
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		loadContent(slug) {
			let self = this;
			self.loading = true;
			Directus.client.api
				.get("/items/content", {
					"filter[status][eq]": "published",
					"filter[slug][eq]": slug,
					fields: "*,translation.*",
				})
				.then((response) => {
					self.content = response.data;
					self.loading = false;
				});
		},
	},
	computed: {},
	created() {
		this.menu = this.$route.params.menu;
		this.slug = this.$route.params.slug;
		this.loadContent(this.slug);
	},
	mounted: function () {},
	watch: {
		$route(to, from) {
			this.slug = this.$route.params.slug;
			this.menu = this.$route.params.menu;
			this.loadContent(this.slug);
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
