<template>
	<layout-default>
		<div>
			<PageLoader :loading="loading"></PageLoader>

			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand@s">
							<h1>{{ $t("Kleurklacht formulier") }}</h1>
							<div class="uk-width-1-1 p-pb-3" v-if="content">
								<div v-html="getLanguageField(content[0], 'html')"></div>
							</div>
						</div>
						<div class="uk-width-1-2@m uk-width-1-3@l uk-width-1-4@xl p-mb-3">
							<Card>
								<template slot="content">
									<div class="eqheight p-mb-3">
										<h3 class="p-mb-2">{{ $t("Afdeling Technical Support") }}</h3>
										<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1234'">+32 11 27 87 32</a></div>
										<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:technicalsupportBNL@ppg.com'">technicalsupportBNL@ppg.com</a></div>
									</div>
								</template>
							</Card>
						</div>
					</div>

					<div v-if="formsend == true">
						<div class="p-mb-5 typography-main">
							<div v-html="getLanguageField(content2[0], 'html')"></div>
						</div>
					</div>

					<div v-if="formsend != true">
						<div class="typography-main">
							<ValidationObserver ref="observer2">
								<div>
									<div class="p-fluid p-grid">
										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>{{ $t("Klantgegevens") }}</h3>
										</div>
										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Contactpersoon">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Contactpersoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Contactpersoon") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required|email" name="E-mail">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="email" v-model="form.Email" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("E-mail") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Vestiginsplaats">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Vestiginsplaats" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Vestigingsplaats") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Adres">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Adres" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Straat + huisnr.") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Telefoon">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Telefoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Telefoon") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Vertegenwoordiger">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Vertegenwoordiger" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Vertegenwoordiger") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>{{ $t("Artikelgegevens") }}</h3>
										</div>

										<div class="p-field p-col-12 p-md-3">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Artikelnummer">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.artikelnummer" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Artikelnummer") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-3">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Merk">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Merk" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Merk") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Artikelnaam">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Artikelnaam" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Artikelnaam") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Partijnummer">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Partijnummer" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Partijnummer") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Volgnummer">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Volgnummer" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Volgnummer") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Basis">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Basis" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Basis") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>{{ $t("Kleurgegevens") }}</h3>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Waaier">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Waaier" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Waaier") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Kleurnaam">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Kleurnaam" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Kleurnaam") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Aantal">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Aantal" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Aantal") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Eenheid">
													<Dropdown v-model="form.Eenheid" :options="units" optionLabel="label" optionValue="value" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Aantal">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<Calendar v-model="form.Receptdatum" selectionMode="single" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Receptdatum") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>{{ $t("Kleurafwijking") }}</h3>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>{{ $t("Is de kleur NAT beoordeeld?") }}</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="BeoordelingNat">
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingNat1" name="BeoordelingNat" value="Ja" v-model="form.BeoordelingNat" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingNat1">{{ $t("Ja") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingNat2" name="BeoordelingNat" value="Nee" v-model="form.BeoordelingNat" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingNat2">{{ $t("Nee") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingNat3" name="BeoordelingNat" value="Weet niet" v-model="form.BeoordelingNat" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingNat3">{{ $t("Weet niet") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>{{ $t("Is de kleur DROOG beoordeeld?") }}</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="BeoordelingDroog">
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingDroog1" name="BeoordelingDroog" value="Ja" v-model="form.BeoordelingDroog" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingDroog1">{{ $t("Ja") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingDroog2" name="BeoordelingDroog" value="Nee" v-model="form.BeoordelingDroog" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingDroog2">{{ $t("Nee") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="BeoordelingDroog3" name="BeoordelingDroog" value="Weet niet" v-model="form.BeoordelingDroog" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="BeoordelingDroog3">{{ $t("Weet niet") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>{{ $t("Zijn de canisters met de juiste kleurpasta gevuld?") }}</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="JuisteKleurpasta">
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="JuisteKleurpasta1" name="JuisteKleurpasta" value="Ja" v-model="form.JuisteKleurpasta" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="JuisteKleurpasta1">{{ $t("Ja") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="JuisteKleurpasta2" name="JuisteKleurpasta" value="Nee" v-model="form.JuisteKleurpasta" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="JuisteKleurpasta2">{{ $t("Nee") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="JuisteKleurpasta3" name="JuisteKleurpasta" value="Weet niet" v-model="form.JuisteKleurpasta" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="JuisteKleurpasta3">{{ $t("Weet niet") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>{{ $t("Zijn alle kleurpasta’s gedoseerd?") }}</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="KleurpastaGedoseerd">
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="KleurpastaGedoseerd1" name="KleurpastaGedoseerd" value="Ja" v-model="form.KleurpastaGedoseerd" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="KleurpastaGedoseerd1">{{ $t("Ja") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="KleurpastaGedoseerd2" name="KleurpastaGedoseerd" value="Nee" v-model="form.KleurpastaGedoseerd" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="KleurpastaGedoseerd2">{{ $t("Nee") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="KleurpastaGedoseerd3" name="KleurpastaGedoseerd" value="Weet niet" v-model="form.KleurpastaGedoseerd" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="KleurpastaGedoseerd3">{{ $t("Weet niet") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>{{ $t("Is de doseeropening schoon?") }}</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="DoseeropeningSchoon">
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="DoseeropeningSchoon1" name="DoseeropeningSchoon" value="Ja" v-model="form.DoseeropeningSchoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="DoseeropeningSchoon1">{{ $t("Ja") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="DoseeropeningSchoon2" name="DoseeropeningSchoon" value="Nee" v-model="form.DoseeropeningSchoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="DoseeropeningSchoon2">{{ $t("Nee") }}</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton id="DoseeropeningSchoon3" name="DoseeropeningSchoon" value="Weet niet" v-model="form.DoseeropeningSchoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="DoseeropeningSchoon3">{{ $t("Weet niet") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-1-1 p-mb-3">
													<strong>{{ $t("Beoordeel altijd bij (kunstmatig) daglicht! Er is afwijking ten opzichte van:") }}</strong>
												</div>

												<div class="uk-width-1-1 p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="Afwijking">
														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking1" name="Afwijking" value="Waaier" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking1">{{ $t("Waaier") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking2" name="Afwijking" value="Folder" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking2">{{ $t("Folder") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking3" name="Afwijking" value="Staal" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking3">{{ $t("Staal") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking4" name="Afwijking" value="Deksel" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking4">{{ $t("Deksel") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking5" name="Afwijking" value="Sticker" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking5">{{ $t("Sticker") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking6" name="Afwijking" value="RM blik" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking6">{{ $t("RM blik") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking7" name="Afwijking" value="Eerder gemengd" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking7">{{ $t("Eerder gemengd") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Afwijking8" name="Afwijking" value="Bestaand werk" v-model="form.Afwijking" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Afwijking8">{{ $t("Bestaand werk") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>{{ $t("Kleurrecept") }}</h3>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-1-1 p-mb-3">
													<ValidationProvider v-slot="v" rules="required" name="Kleurrecept">
														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept1" name="Kleurrecept" value="Te licht" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept1">{{ $t("Te licht") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept2" name="Kleurrecept" value="Te donker" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept2">{{ $t("Te donker") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept3" name="Kleurrecept" value="Te rood" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept3">{{ $t("Te rood") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept4" name="Kleurrecept" value="Te geel" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept4">{{ $t("Te geel") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept5" name="Kleurrecept" value="Te groen" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept5">{{ $t("Te groen") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept6" name="Kleurrecept" value="Te blauw" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept6">{{ $t("Te blauw") }}</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox id="Kleurrecept7" name="Kleurrecept" value="Te grijs" v-model="form.Kleurrecept" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
															<label for="Kleurrecept7">{{ $t("Te grijs") }}</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="Pasta">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Pasta" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Pasta") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="required" name="AantalShots">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="number" v-model="form.AantalShots" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Aantal shots") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider v-slot="v" rules="" name="Pasta">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Pasta2" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Pasta") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="" name="AantalShots">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="number" v-model="form.AantalShots2" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Aantal shots") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider v-slot="v" rules="" name="Pasta">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Pasta3" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Pasta") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="" name="AantalShots">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="number" v-model="form.AantalShots3" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Aantal shots") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider v-slot="v" rules="" name="Pasta">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="text" v-model="form.Pasta4" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Pasta") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider v-slot="v" rules="" name="AantalShots">
													<span class="p-float-label p-input-icon-right">
														<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
														<InputText type="number" v-model="form.AantalShots4" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
														<label>{{ $t("Aantal shots") }}</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12">
											<div class="p-mb-2">
												<h3>{{ $t("Duidelijke foto van de kleur/ afwijkende kleur") }}</h3>
											</div>

											<FileUpload
												name="files[]"
												ref="fileUploadFotos"
												@upload="onUpload"
												@uploader="targafilesUpload"
												:multiple="true"
												accept="image/jpg,image/jpeg,image/png,image/bmp,image/tif,image/tiff,application/pdf"
												:maxFileSize="30000000"
												:chooseLabel="$t('Selecteer')"
												uploadLabel="Upload"
												cancelLabel="Annuleer"
												:customUpload="true"
											>
												<template #empty>
													<p>{{ $t("Sleep je foto's hierheen of klik op 'selecteer'.") }}</p>
												</template>
											</FileUpload>
										</div>

										<div class="p-field p-col-12">
											<Button :label="$t('Verstuur')" icon="pi pi-send" class="p-button-lg" style="width:auto;" @click="submitForm()" />
										</div>
									</div>
								</div>
							</ValidationObserver>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import PageLoader from "@/components/layout/PageLoader";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		PageLoader,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		Calendar,
		RadioButton,
		Checkbox,
		FileUpload,
	},
	data() {
		return {
			loading: false,
			form: {
				Eenheid: "Ltr",
				files: [],
			},
			formsend: false,
			files: [],
			units: [
				{ value: "Ltr", label: this.$t("Liter") },
				{ value: "Kg", label: this.$t("Kilogram") },
			],
			settings: [{}],
			content: [{}],
			content2: [{}],
		};
	},
	props: {},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		onUpload() {},

		targafilesUpload(e) {
			let self = this;
			console.log(e);

			Axios.post(self.settings[0].url_targafiles + "/Auth/token", { apiKey: self.settings[0].apikey_targafiles, scope: "read write" })
				.then((response) => {
					let token = response.data.access_token;
					let URL = self.settings[0].url_targafiles + "/Files/" + self.settings[0].project_targafiles + "/kleurklachten/" + JSON.parse(self.$cookie.get("auth")).data.user.email + "?token=" + token;

					let config = {
						header: {
							"Content-Type": "multipart/form-data",
						},
					};
					if (e && e.files) {
						e.files.forEach((file, index) => {
							let data = new FormData();
							data.append("file", file);
							console.log(file);

							Axios.post(URL, data, config)
								.then((response) => {
									console.log("response", response);
									self.form.files.push(response.data.url);
									if (index == e.files.length - 1) {
										console.log(self.form.files);

										self.submitForm2();
									}
								})
								.catch((error) => {
									console.log("error", error);
								});
						});
					}
				})
				.catch((error) => {
					console.log("error", error);
				});
		},
		submitForm() {
			let self = this;
			let observer = self.$refs["observer2"];
			if (observer.flags.valid == true) {
				if (self.$refs["fileUploadFotos"] && self.$refs["fileUploadFotos"].files && self.$refs["fileUploadFotos"].files.length > 0) {
					self.$refs["fileUploadFotos"].upload();
				} else {
					self.submitForm2();
				}
			}
		},
		submitForm2() {
			let self = this;
			let body = "";
			Object.keys(self.form).forEach((key) => {
				if (key != "files") {
					body = body + "<b>" + key + "</b>: " + self.form[key] + "<br>";
				}
			});

			if (self.form.files && self.form.files.length > 0) {
				body = body + "<br><b>Files</b>:<br>";
				self.form.files.forEach((url) => {
					body = body + encodeURI(url) + "<br><br>";
				});
			}
			body = body + "<br><b>User (logged in)</b>:<br>";
			body = body + "<b>id</b>: " + self.user.id + "<br>";
			body = body + "<b>email</b>: " + self.user.email + "<br>";
			body = body + "<b>customer_id</b>: " + self.user.customer_id + "<br>";
			body = body + "<b>company</b>: " + self.user.company + "<br>";
			body = body + "<b>first_name</b>: " + self.user.first_name + "<br>";
			body = body + "<b>last_name</b>: " + self.user.last_name + "<br>";

			console.log(body);

			Directus.client2.api
				.post("/mail", {
					to: ["AMS-MB.ColourServices@ppg.com", "dev@targateam.nl"],
					from: "noreply@ppgretailbe.com",
					subject: "Kleurklacht via ppg-retail.be",
					body: body,
					type: "html",
					data: {},
				})
				.then((response) => {
					self.formsend = true;
				});
		},
	},
	created() {
		let self = this;

		self.user = self.$route.params.user;

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "kleurklacht",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content = response.data;
			});

		Directus.client.api
			.get("/items/settings", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,wholesaler_xls.*",
			})
			.then((response) => {
				this.settings = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "kleurklacht-bedankt",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.content2 = response.data;
			});
	},
};
</script>

<style lang="scss">
.p-fileupload .p-fileupload-buttonbar button {
	display: none;
}
</style>
