<template>
	<div class="uk-position-absolute uk-position-top t-bg-white uk-height-viewport uk-width-1-1" style="z-index:100000;" v-if="loading == true">
		<div class="uk-position-center">
			<ProgressSpinner></ProgressSpinner>
		</div>
	</div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
	components: {
		ProgressSpinner,
	},
	props: {
		loading: Boolean,
	},
};
</script>
